import { Component, OnInit } from '@angular/core';
import { Setting } from '../../pages/models/about';
import { AboutService } from '../../pages/services/about.service';

@Component({
  selector: 'app-app-download',
  templateUrl: './app-download.component.html',
  styleUrls: ['./app-download.component.scss']
})
export class AppDownloadComponent implements OnInit {

  constructor(private aboutService: AboutService) { }
  getsettingModel!:Setting;
  getcommunicationModel!:Setting;


  ngOnInit(): void {
    this.getsettingList();
    this.getcommunicationList();

  }
  
  getsettingList(){
    this.aboutService.get("site_telefon").subscribe(data=> {
      this.getsettingModel = data;
      // console.log("Ad",this.getsettingModel)
    })
  }
  getcommunicationList(){
    this.aboutService.get("communication").subscribe(data=> {
      this.getcommunicationModel = data;
      // console.log("Ad",this.getcommunicationModel)
    })
  }

}
