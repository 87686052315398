import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../pages/services/about.service';
import { Setting } from '../../pages/models/about';

@Component({
    selector: 'app-header-one',
    templateUrl: './header-one.component.html',
    styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

    constructor(private aboutService: AboutService) { }
    getsettingModel!:Setting;
    getaddressModel!:Setting;
    getslogModel!:Setting;
    getphoneModel!:Setting;


    ngOnInit(): void {
        this.getsettingList();
        this.getaddressList();
        this.getslogList();
        this.getphoneList();
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    
    
  getsettingList(){
    this.aboutService.get("site_adi").subscribe(data=> {
      this.getsettingModel = data;
      // console.log("Hakkımızda",this.getsettingModel)
    })
  }
  getaddressList(){
    this.aboutService.get("site_adres").subscribe(data=> {
      this.getaddressModel = data;
      // console.log("Hakkımızda",this.getsettingModel)
    })
  }
  getslogList(){
    this.aboutService.get("slogan").subscribe(data=> {
      this.getslogModel = data;
      // console.log("Ad",this.getslogModel)
    })
  }
  getphoneList(){
    this.aboutService.get("site_telefon	").subscribe(data=> {
      this.getphoneModel = data;
      // console.log("Bbbb",this.getphoneModel)
    })
  }

}