<header class="header-area">
    <div class="top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="header-contact-info">
                        <!-- <li>İvati Reisen</li> -->
                        <li><a href="https://www.facebook.com/ivatireisen/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/ivatireisen/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><i class='bx bx-phone-call'></i> <a style="margin-left: 3px;" href="tel:+41768035434">+41 76 803 54 34</a></li>
                        <li><i class='bx bx-envelope'></i> <a style="margin-left: 3px;" href="mailto:group@ivati.ch"> group@ivati.ch</a></li>


                        <!-- Dil listesi ileride lazım olucak...
                        <li>
                            <div class="dropdown language-switcher d-inline-block">
                                <button class="dropdown-toggle" type="button" (click)="toggleClass()">
                                    <img src="assets/img/us-flag.jpg" alt="image">
                                    <span>Eng <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu" [class.active]="classApplied">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Ger</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Fre</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Spa</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Rus</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                                        <span>Ita</span>
                                    </a>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
                <!-- <div class="col-lg-4 col-md-12">
                    <div class="top-header-discount-info">
                        <p><strong>Tüm Turlarda </strong> %50 <a href="listing-1.html">İndirim!</a></p>
                    </div>
                </div> -->
                
                <!-- <div class="col-lg-4 col-md-12">
                    <ul class="header-top-menu">
                        <li><a routerLink="/login"><i class='bx bxs-user'></i> My Account</a></li>
                        <li><a href="/admin-dashboard/add-listing"><i class='bx bx-plus-circle'></i> Add Listing</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Register</a></li>
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                    </ul>
                </div> -->
                
            </div>
        </div>
    </div>
    <div class="navbar-area">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                        <a routerLink="/" >
                            <img src="assets/img/logo.png" alt="logo" style="position: relative;left: -6%;top: -16px;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="louise-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/" style="float: left;position: relative;top: -7.8px;left: -3.3%">
                        <img src="assets/img/logo.png" alt="logo" style="width: 46%;">
                    </a>
                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Anasayfa</a></li>
                            <li class="nav-item"><a routerLink="/listing-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Turlar</a></li>
                            <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hakkımızda</a></li>
                            <!-- <li class="nav-item"><a routerLink="/tour-request" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tur Talebi</a></li> -->
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">İletişim</a></li>
                            
                        </ul>
                    </div>
                <a class="navbar-brand" routerLink="/" style="float: right;position: relative;top: -9px;left: 12%">
                    <img src="assets/img/SağDetay-01.png" alt="logo" style="width: 46%;">
                </a>
                </nav>
            </div>
        </div>
    </div>
</header>