<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Listing</h2>
        </div>
    </div>
</div>

<section class="listing-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="listing-filter-options">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <p>Showing 1 – 18 of 100</p>
                        </div>

                        <div class="col-lg-4">
                            <div class="listing-ordering-list">
                                <select>
                                    <option>Sort by Price: Low to High</option>
                                    <option>Default Sorting</option>
                                    <option>Sort by Popularity</option>
                                    <option>Sort by Average Rating</option>
                                    <option>Sort by Latest</option>
                                    <option>Sort by Price: High to Low</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img1.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user1.jpg" alt="image">
                                <span>John Smith</span>
                            </div>

                            <h3><a routerLink="/blog-details">The Goggi Restaurant</a></h3>
                            <span class="location"><i class='bx bx-map'></i> 40 Journal Square, NG USA</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-wine'></i>
                                        Restaurant
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img2.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user2.jpg" alt="image">
                                <span>Sarah Taylor</span>
                            </div>

                            <h3><a routerLink="/blog-details">Shop in Aurora Center</a></h3>
                            <span class="location"><i class='bx bx-map'></i> 55 County Laois, Ireland</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Pricey">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class="bx bx-shopping-bag"></i>
                                        Shopping
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img3.jpg" alt="image">
                    </a>

                    <div class="listing-badge closed">Closed Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user3.jpg" alt="image">
                                <span>James Andy</span>
                            </div>

                            <h3><a routerLink="/blog-details">USA Fitness Club</a></h3>
                            <span class="location"><i class='bx bx-map'></i> Tilt Tilbury, United Kingdom</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Moderate">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-blanket'></i>
                                        Fitness
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img4.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user4.jpg" alt="image">
                                <span>David Warner</span>
                            </div>

                            <h3><a routerLink="/blog-details">The Lounge & Bar</a></h3>
                            <span class="location"><i class='bx bx-map'></i> The Ritz-Carlton, Hong Kong</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-wine'></i>
                                        Bar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img5.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user1.jpg" alt="image">
                                <span>John Smith</span>
                            </div>

                            <h3><a routerLink="/blog-details">The Magician Restaurant in USA</a></h3>
                            <span class="location"><i class='bx bx-map'></i> 40 Journal Square, NG USA</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-wine'></i>
                                        Restaurant
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img6.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user2.jpg" alt="image">
                                <span>Sarah Taylor</span>
                            </div>

                            <h3><a routerLink="/blog-details">Centro Comercial Aricanduva</a></h3>
                            <span class="location"><i class='bx bx-map'></i> 55 County Laois, Ireland</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Pricey">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class="bx bx-shopping-bag"></i>
                                        Shopping
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/listing-three" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/listing-three" class="page-numbers">2</a>
                    <a routerLink="/listing-three" class="page-numbers">3</a>
                    <a routerLink="/listing-three" class="page-numbers">4</a>
                    <a routerLink="/listing-three" class="page-numbers">5</a>
                    <a routerLink="/listing-three" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>