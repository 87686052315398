<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides owl-carousel owl-theme">
            
            <div class="single-feedback-item">
                <p>“Gezi programından çok memnun kaldım,ilgi  ve alakanız için teşekkürler.”</p>

                <div class="info">
                    <h3>Murat Kuyubaşı</h3>
                    <span>Türkiye</span>
                    <!-- <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image"> -->
                </div>
            </div>

            <div class="single-feedback-item">
                <p>“Güzel bir tur gerçekleştirmek için doğru bir tercih yaptık, herkese tavsiye ederim. ”</p>

                <div class="info">
                    <h3>Oğuzhan Kıran</h3>
                    <span>Rusya</span>
                    <!-- <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image"> -->
                </div>
            </div>

            <div class="single-feedback-item">
                <p>“Planlanan seyahatler, verilen bilgiler ve yapılan yönlendirmeler ile rehberlik konusunda sıkıntı yaşanmadan çok keyifli bir şekilde gezimiz tamamlanmıştır.
                    Teşekkür ederiz.”</p>

                <div class="info">
                    <h3>Bekir Yıldırım</h3>
                    <span>İsviçre</span>
                    <!-- <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image"> -->
                </div>
            </div>
        </div>
    </div>
</section>