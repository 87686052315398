<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <!-- <div class="page-title-content">
            <h2>Listing Details</h2>
        </div> -->
    </div>
</div>

<section class="listing-details-area pt-100 pb-70">
    <div class="container">
        <div class="listing-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="listing-title">
                        <h2>{{gettourModel.tourRecord.title}}</h2>
                        <p>{{gettourModel.shortDescription}}</p>
                    </div>

                    <div class="listing-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Kontenjan</span>
                                <a routerLink="/">{{gettourModel?.quota-gettourModel.reservationTotalPerson}}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Rezervasyon olan kİşİ sayısı</span>
                                <a routerLink="/">{{gettourModel.reservationTotalPerson}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Tur Tarihi</span>
                                <a routerLink="/"> {{gettourModel?.startDate | date:'dd/MM/yyyy' :'TR'}} - {{gettourModel?.endDate | date:'dd/MM/yyyy' :'TR'}} </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- {{gettourModel?.dayCount-1}} Gece {{gettourModel?.dayCount}} Gün | -->
                <!-- <div class="col-lg-4">
                    <div class="listing-price">
                        <div class="listing-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div>

                        <div class="price">$80 - $250</div>
                        <a routerLink="/listing-details" class="default-btn">Claim Now!</a>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8 col-md-12" >
                <div class="listing-details-image-slides owl-carousel owl-theme">
                    <div class="listing-details-image text-center" *ngFor="let item of gettourModel?.tourMedias;">
                        <img [src]="path+'tours/'+item.fileName">
                    </div>

                </div>
                

                <div class="listing-details-desc">
                    <h3>Genel Bilgiler
                    </h3>
                    <p>{{gettourModel.description}}</p>

                    <!-- <ul class="description-features-list">
                        <li>Feasibility and Economic Studies</li>
                        <li>Design Coordination</li>
                        <li>Pro Forma Financial Analysis</li>
                        <li>Scheduling</li>
                        <li>Contact Cegotiation</li>
                    </ul> -->
                    <section class="faq-area ptb-100">
                        <div class="container">
                            <div class="tab faq-accordion-tab">
                                <ul class="tabs d-flex flex-wrap justify-content-center">
                                    <li><a href="#"><i class='bx bx-flag'></i> <span>Tur Akışı</span></a></li>
                                    <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span>Tura Dahil Olan</span></a></li>
                                    <!-- <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Genel Bilgiler</span></a></li>  -->
                                </ul>
                                <div class="tab-content">
                                    <div class="tabs-item">
                                        <div class="faq-accordion accordion" id="faqAccordion" >
                                            <div class="accordion-item" *ngFor="let item of gettourModel?.tourDays; let i = index;">
                                                <div class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" attr.data-bs-target="#collapse{{i}}" aria-expanded="true" attr.aria-controls="collapse{{i}}">
                                                        {{item.title}} <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapse{{i}}" class="accordion-collapse collapse" data-bs-parent="#faqAccordion1">
                                                    <div class="accordion-body">
                                                        <p innerHTML="{{item.description}}"> </p>
                                                    </div>
                                                </div>
                                            </div>
                                
                             
                                        </div>
                                    </div>
                                    <div class="tabs-item">
                                        <div class="faq-accordion accordion" id="faqAccordion2">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                                        Tura Dahil Olan <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                                    <div class="accordion-body">
                                                        <ul *ngFor="let item of gettourModel.tourSpecifications"> 
                                                            <p *ngIf="item.inPrice == true" innerHTML="{{item.specification}}"> </p>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                        Tura Dahil Olmayan <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                                                    <div class="accordion-body">
                                                        <ul *ngFor="let item of gettourModel.tourSpecifications">
                                                            <p *ngIf="item.inPrice == false" innerHTML="{{item.specification}}"> </p>
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                     
                                        </div>
                                    </div>
                                    <!-- <div class="tabs-item">
                                        <div class="faq-accordion accordion" id="faqAccordion3">
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                                        Diğer Önemli Bilgiler <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseEleven" class="accordion-collapse collapse " data-bs-parent="#faqAccordion3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <p innerHTML="{{gettourModel.tourMainRecords[0].description}}"> </p>
                    
                                                        </ul>                                </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <div class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                        Önemli Bilgiler <i class='bx bx-chevron-down'></i>
                                                    </button>
                                                </div>
                                                <div id="collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                        <p innerHTML="{{gettourModel.tourMainRecords[0].secondDescription}}"> </p>
                    
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                        
                                        </div>
                                    </div>  -->
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- <h3>Aktiviteler</h3> -->

                    <!-- <div class="amenities-list">
                        <ul>
                            <li>
                                
                                <span>
                                    <i class='bx bx-check'></i>
                                    Elevator in Building
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Instant Book
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Friendly Workspace
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Wireless Internet
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Free Parking on Premises
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Free Parking on Street
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Air Conditioned
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Live Music
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Accepting Credit Cards
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Pool
                                </span>
                            </li>
                        </ul>
                    </div> -->

                    <!-- <h3>Konum</h3> -->

                    <!-- <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3108166.8734399383!2d28.69441011772157!3d40.46820255762592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34e42e68a7fef%3A0xeff206856f992fc8!2zTcOcU0RBVg!5e0!3m2!1str!2str!4v1682337164314!5m2!1str!2str"></iframe>
                    </div> -->

                    <!-- <h3>Önemli Not</h3>

                    <div class="listing-author" >
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image">

                                <div class="author-profile-title-details">
                                    <div class="author-profile-details">
                                        <h4>Murat Kuyubaşı</h4>
                                    </div>
                                </div>
                            </div>
                            <p>NOT: GİDİLECEK ÜLKENİN PANDEMİ KURALLARI GEÇERLİ OLACAĞINDAN PANDEMİ ŞARTLARININ
                                SAĞLANMASI YOLCUNUN SORUMLULUĞUNDADIR.</p>
                        </div>
                    </div> -->

                     <div class="listing-review-comments">
                        <h3>{{getcommentlistModel.length}} Yorum</h3>

                        <div class="user-review" *ngFor="let item of getcommentlistModel">
                             <div class="review-rating">
                                <div class="review-stars"  >
                                     <i class='bx bxs-star'>{{item.point}}</i>
                                </div>
                            </div>


                            <strong class="d-block sub-comment">{{item.fullName}}</strong>
                             <span>{{item.creationDate | date:'dd/MM/yyyy' :'TR'}}</span>

                            <p>{{item.comment}}</p>
                        </div>
                    </div>
                    
                </div>

                <div class="comments-area">

                    <div class="comment-respond">
                        <h3 class="comment-reply-title">Yorum Bırak</h3>

                        <form id="contactForm" [formGroup]="form" (ngSubmit)="submitForm()">
                            <p class="comment-notes">
                                <span id="email-notes">E-posta hesabınız yayımlanmayacak.</span>
                                <span class="required">Zorunlu alanlar *</span>
                                ile işaretlenmiştir
                            </p>
                            <p class="comment-form-author">
                                <label>Ad<span>*</span></label>
                                    <input type="text"  name="fullName" formControlName="fullName" id="name" class="form-control" placeholder="Ad">
                            </p>
                            <!-- <p class="comment-form-author">
                                <label>Soyad <span>*</span></label>
                                <input type="text"  name="lastName" formControlName="lastName" id="name" class="form-control" placeholder="Soyad">
                            </p> -->
                            <p class="comment-form-author">
                                <label>E-Posta <span>*</span></label>
                                    <input type="email" id="email" name="email" formControlName="email"  class="form-control" placeholder="E-Posta Adresiniz">
                            </p>
                            
                            <!-- <p class="comment-form-author">
                                <label>Konu<span>*</span></label>
                                <input type="text"  name="subject" formControlName="subject" id="name" class="form-control" placeholder="Mesajınızı yazınız.">
                            </p> -->
                            <p class="comment-form-comment">
                                <label>Yorum <span>*</span></label>
                                <textarea name="comment" id="comment" formControlName="comment" cols="30" rows="5" class="form-control" placeholder="Mesajınızı yazınız..."></textarea>
                            </p>
                            <!-- <p class="comment-form-cookies-consent">
                                <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                            </p> -->
                            <p class="form-submit">
                                <input type="submit" name="submit" id="submit" class="submit" value="Yorum Gönder">
                            </p>
                        </form>
                    </div>
                </div>
                
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="listing-sidebar-widget">
                    <div class="listing-opening-hours">
                        <!-- <h3>Tur Akışı Açılış saatleri</h3> -->

                        <!-- <ul>
                            <li><span>Pazartesi:</span> 09.00 - 18.00</li>
                            <li><span>Salı:</span> 09.00 - 18.00</li>
                            <li><span>Çarşamba:</span>09.00 - 18.00</li>
                            <li><span>Perşembe:</span> 09.00 - 18.00</li>
                            <li><span>Cuma:</span>09.00 - 18.00</li>
                           
                        </ul> -->
                    </div>

                    <!-- <div class="listing-contact-info">
                        <h3>  Konum Bilgisi</h3>

                        <ul>
                            <li><span>Hava Limanı:</span> <a href="#">Havalimanı: 105 km</a></li>
                            <li><span>Gezilip Görülecek Yerler:</span><br>Kalebaşı Camii: 7199 m <br> Üyük Oba Yayla Camii: 7413 m<br>
                                Düz Mahalle Camii: 9014 m<br>
                                 Canikdere Şelalesi: 23 km</li>
                            
                        </ul>
                    </div> -->

                    <div class="listing-book-table">
                        <h3>Rezervasyon</h3>

                        <form>
          
                            <div class="cart-totals">
                                <h3>Tur Ücreti  {{countedPrice}} CHF</h3>

                                <ul >
                                    <sup *ngIf="adultCount < 2" class="price"> <p style="color: #20aef9;">  Tek kişilik oda farkı + {{onePersonPrice}}CHF</p></sup>

                                    <div class="input-counter"  style="float: left;left: -13px;"> 
                                        <span (click)="calculateTotalPrice(adultCount-1)" class="minus-btn"><i class='bx bx-minus'></i></span>
                                        <input (change)="calculateTotalPrice($event.target.value)" [(ngModel)]="adultCount" id="guests" type="text" min="1" value="2"  style="height: 54px;">
                                        <span (click)="calculateTotalPrice(adultCount+1)"  class="plus-btn"><i class='bx bx-plus'></i></span>
                                    </div>
                                    <li style="height: 55px;"><span style="float: unset;margin-left: -3%;"> Yetişkin  </span><img src="assets/img/yetis╠ğkin.png" alt="" style="margin-left: -9%;margin-top: -2px;width: 30%;"> </li>
                                    
                                </ul>
                                <!-- <ul >
                                  
                                    <div class="input-counter"  style="float: left;left: -13px;" > 
                                        <span (click)="calculateChildPrice(childCount-1)" class="minus-btn"><i class='bx bx-minus'></i></span>
                                        <input (change)="calculateChildPrice($event.target.value)" [(ngModel)]="childCount" id="guestsC" type="text" min="0" max="3" value="0" style="height: 54px;">
                                        <span (click)="calculateChildPrice(childCount+1)" class="plus-btn"><i class='bx bx-plus'></i></span>
                                    </div>
                                    <li><span style="float: unset;"> Çocuk  </span><img src="assets/img/cocuk.png" alt="" style="width: 19%;"> </li>
                                    
                                </ul> -->
                                <div *ngIf="showChildPrice">
                                    <div *ngIf="childCount>0">
                                        1. Çocuk <span *ngIf="this.child1>0">{{this.child1}} CHF</span>
                                        <select class="form-control"  name="child1" [(ngModel)]="child1" (change)="setChild($event)" >
                                            <option *ngFor=" let item of gettourModel?.tourPrices | featuredFilter : true"  value="{{item.price}}">{{item.title}}</option>
                                        </select>
                                    </div>
                                </div>
                                <h3></h3>
                         
                <button type="submit" (click)="setReservation()" class="default-btn"  *ngIf="gettourModel?.quota-gettourModel.reservationTotalPerson!=0">Rezervasyon Yap</button>
                <button type="submit" class="default-btn"  *ngIf="gettourModel?.quota-gettourModel.reservationTotalPerson ==0">Rezervasyon Kapalı</button>

                            </div>
                        </form>
                    </div>

                    <!-- <div class="listing-instagram">
                        <h3>Instagram</h3>

                        <ul>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img4.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img5.jpg" alt="image">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="d-block">
                                    <img src="assets/img/blog/img6.jpg" alt="image">
                                </a>
                            </li>
                        </ul>
                    </div> -->

                    <!-- <div class="listing-contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>