import { Component, OnInit } from '@angular/core';
import { AboutService } from '../services/about.service';
import { Setting } from '../models/about';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private aboutService: AboutService) { }
  getaboutModel!:Setting;
  getmissionModel!:Setting;
  getvisionModel!:Setting;
  getwhyweexistModel!:Setting;
  

  


  ngOnInit(): void {
    this.gettourList();
    this.getmissionList();
    this.getvisionlist();
    this.getwhyweexistlist();
  }

  gettourList(){
    this.aboutService.get("hakkimizda").subscribe(data=> {
      this.getaboutModel = data;
      // console.log("Hakkımızda",this.getaboutModel)
    })
  }
  getmissionList(){
    this.aboutService.get("misyon").subscribe(data=> {
      this.getmissionModel = data;
      // console.log("Misyon",this.getmissionModel)
    })
  }
  getvisionlist(){
    this.aboutService.get("vizyon").subscribe(data=> {
      this.getvisionModel = data;
      // console.log("Vizyon",this.getvisionModel)
    })
  }
  getwhyweexistlist(){
    this.aboutService.get("nedenvariz").subscribe(data=> {
      this.getwhyweexistModel = data;
      // console.log("Neden variz",this.getwhyweexistModel)
    })
  }

}
