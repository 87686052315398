<app-header-two></app-header-two>

<section class="main-banner-wrapper">
    <div class="container">
        <div class="banner-wrapper-content">
            <h1>Search local directories and 
                <ngx-typed-js [strings]="['Restaurants', 'Hotels', 'Shops', 'Fitness', 'Shopping']" [typeSpeed]="100" [loop]="true" [backSpeed]="100">
                    <span class="typing"></span>
                </ngx-typed-js> here
            </h1>
            <p>Find directories around the country. Add your business to Braike and make sure customers always find the right information about your business.</p>
            <form>
                <input type="text" class="input-search" placeholder="What are you looking for?">
                <button type="button">Search</button>
            </form>
        </div>
    </div>
</section>

<section class="listing-categories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/categories-two" class="section-title-btn">See All <i class='bx bx-chevrons-right'></i></a>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg1">
                    <div class="icon">
                        <i class='bx bx-shopping-bag'></i>
                    </div>
                    <h3>Shops</h3>
                    <span>60 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg2">
                    <div class="icon">
                        <i class='bx bx-bed'></i>
                    </div>
                    <h3>Hotels</h3>
                    <span>21 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg3">
                    <div class="icon">
                        <i class='bx bx-drink'></i>
                    </div>
                    <h3>Restaurants</h3>
                    <span>58 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg4">
                    <div class="icon">
                        <i class='bx bx-dumbbell'></i>
                    </div>
                    <h3>Fitness</h3>
                    <span>99 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg5">
                    <div class="icon">
                        <i class='bx bx-calendar-star'></i>
                    </div>
                    <h3>Events</h3>
                    <span>21 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg6">
                    <div class="icon">
                        <i class='bx bx-walk'></i>
                    </div>
                    <h3>Services</h3>
                    <span>49 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg7">
                    <div class="icon">
                        <i class='bx bx-building-house'></i>
                    </div>
                    <h3>Hospital</h3>
                    <span>21 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-listing-item bg8">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>Others</h3>
                    <span>12 Listing</span>

                    <a routerLink="/listing-two" class="learn-more-btn">Learn More <i class='bx bx-chevron-right'></i></a>

                    <a routerLink="listing-2.html" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="listing-area pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Listing</span>
            <h2>Trending Listing</h2>
            <a routerLink="/listing-one" class="section-title-btn">See All <i class='bx bx-chevrons-right'></i></a>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img5.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Farmis Garden Hotel & Restaurant</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img6.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Skyview Shopping Complex Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img7.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Power House Gym Training Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="listing-area pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Listing</span>
            <h2>Most Popular Listing</h2>
            <a routerLink="/listing-one" class="section-title-btn">See All <i class='bx bx-chevrons-right'></i></a>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img8.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Restaurant</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">The Magician Restaurant in USA</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 40 Journal Square, NG USA</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                    $1500 - $2000
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img9.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Shopping</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                5.0 (1 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">New Oriental Shopping Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> 55 County Laois, Ireland</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-toggle="tooltip" data-placement="top" title="Pricey">
                                    $100 - $200
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge closed">Closed!</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-listing-item">
                    <div class="listing-image">
                        <a routerLink="/listing-details" class="d-block"><img src="assets/img/listing/img10.jpg" alt="image"></a>

                        <div class="listing-tag">
                            <a routerLink="/" class="d-block">Gym</a>
                        </div>

                        <div class="listing-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>

                            <div class="rating-total">
                                4.5 (2 reviews)
                            </div>
                        </div>
                    </div>

                    <div class="listing-content">
                        <div class="listing-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Andy</span>
                        </div>

                        <h3><a routerLink="/listing-details" class="d-inline-block">Looks Women's Fitness Center</a></h3>

                        <span class="location"><i class="bx bx-map"></i> Tilt Tilbury, United Kingdom</span>
                    </div>

                    <div class="listing-box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price">
                                <span data-toggle="tooltip" data-placement="top" title="Moderate">
                                    $240 - $250
                                </span>
                            </div>

                            <div class="listing-option-list">
                                <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="listing-badge">Open!</div>
                    
                    <span class="listing-discount">
                        <span>-15% OFF</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="listing-area pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Listing</span>
            <h2>Most Recent Listing</h2>
            <a routerLink="/listing-one" class="section-title-btn">See All <i class='bx bx-chevrons-right'></i></a>
        </div>

        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img1.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user1.jpg" alt="image">
                                <span>John Smith</span>
                            </div>

                            <h3><a routerLink="/listing-details">The Goggi Restaurant</a></h3>
                            <span class="location"><i class='bx bx-map'></i> 40 Journal Square, NG USA</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-wine'></i>
                                        Restaurant
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-6 col-sm-12 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img2.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user2.jpg" alt="image">
                                <span>Sarah Taylor</span>
                            </div>

                            <h3><a routerLink="/listing-details">Shop in Aurora Center</a></h3>
                            <span class="location"><i class='bx bx-map'></i> 55 County Laois, Ireland</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Pricey">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class="bx bx-shopping-bag"></i>
                                        Shopping
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img3.jpg" alt="image">
                    </a>

                    <div class="listing-badge closed">Closed Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user3.jpg" alt="image">
                                <span>James Andy</span>
                            </div>

                            <h3><a routerLink="/listing-details">USA Fitness Club</a></h3>
                            <span class="location"><i class='bx bx-map'></i> Tilt Tilbury, United Kingdom</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Moderate">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-blanket'></i>
                                        Fitness
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-sm-12 col-md-12">
                <div class="single-listing-box">
                    <a routerLink="/listing-details" class="listing-image">
                        <img src="assets/img/listing/img4.jpg" alt="image">
                    </a>

                    <div class="listing-badge">Open Now</div>

                    <div class="listing-content">
                        <div class="content">
                            <div class="author">
                                <img src="assets/img/user4.jpg" alt="image">
                                <span>David Warner</span>
                            </div>

                            <h3><a routerLink="/listing-details">The Lounge & Bar</a></h3>
                            <span class="location"><i class='bx bx-map'></i> The Ritz-Carlton, Hong Kong</span>

                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span class="rating-count">2 reviews</span>
                            </div>
                        </div>

                        <div class="footer-content">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="listing-option-list">
                                    <a routerLink="/" title="Find Directions"><i class='bx bx-directions'></i></a>
                                    <a routerLink="/" title="Save"><i class='bx bx-heart'></i></a>
                                    <a routerLink="/" title="On the Map"><i class='bx bx-map'></i></a>
                                </div>

                                <div class="price-level">
                                    <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                        <strong>$</strong>
                                    </span>
                                </div>

                                <div class="listing-category">
                                    <a routerLink="/" data-toggle="tooltip" data-placement="top" title="Category">
                                        <i class='bx bx-wine'></i>
                                        Bar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="destinations-area pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Browse</span>
            <h2>Popular Destinations</h2>
            <a routerLink="/listing-two" class="section-title-btn">See All <i class='bx bx-chevrons-right'></i></a>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="assets/img/destinations/img1.jpg" alt="image">

                    <div class="content">
                        <h3>New York</h3>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>

                    <span class="listing-count">125 Listings</span>

                    <a routerLink="/listing-one" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="assets/img/destinations/img2.jpg" alt="image">

                    <div class="content">
                        <h3>Paris</h3>
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                    </div>

                    <span class="listing-count">50 Listings</span>

                    <a routerLink="/listing-one" class="link-btn"></a>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="assets/img/destinations/img3.jpg" alt="image">
        
                            <div class="content">
                                <h3>Moscow</h3>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
        
                            <span class="listing-count">15 Listings</span>
        
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="assets/img/destinations/img4.jpg" alt="image">
        
                            <div class="content">
                                <h3>London</h3>
                                <div class="rating">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                            </div>
        
                            <span class="listing-count">78 Listings</span>
        
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-process></app-process>

<app-feedback></app-feedback>

<app-partner></app-partner>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Recent Story</span>
            <h2>From The Louise Blog</h2>
            <a routerLink="/blog-one" class="section-title-btn">See All <i class='bx bx-chevrons-right'></i></a>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-one">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Ideas</span>
                        <h3><a routerLink="/blog-details-one">Top 50 places to eat in Milwaukee</a></h3>
                        <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-one">
                            <img src="assets/img/blog/img2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Advice</span>
                        <h3><a routerLink="/blog-details-one">28 places to visit in the USA</a></h3>
                        <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details-one">
                            <img src="assets/img/blog/img3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Social</span>
                        <h3><a routerLink="/blog-details-one">08 things to remember in Morning</a></h3>
                        <a routerLink="/blog-details-one" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>