import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TourResource } from "../models/tour-resource";
import { Reservation } from "../models/reservation";

@Injectable({
    providedIn: 'root'
  })
  export class FrontApiService {
  
    path:any;
    constructor(
      private http:HttpClient
    ) {
      this.path = environment.apiUrl;
     }
  
     getAllTours():Observable<any>{
      const url= `/tour/getalltours`;
      return this.http.get<any>(this.path+url)
     }
  
     getAllToursByLang(lang:string):Observable<any>{
      const url= `/tour/getalltoursbylang/${lang}`;
      return this.http.get<any>(this.path+url)
     }
  
     searchTour(resource:TourResource):Observable<any>{
      const url= `/tour/SearchTour`;
      const customParams = new HttpParams()
      .set('languageCode', "tr")
      .set('name', resource.SearchTerm)
      .set('regionName',resource.RegionName)
      .set('periodName',resource.PeriodName)
      .set('regionId',resource.RegionId)
      .set('periodId',resource.PeriodId)
      .set('categoryName',resource.CategoryName)
      .set('exitPointName',resource.ExitPointName)
      .set('transportaionName',resource.TransportaionName)
              return this.http.get<any[]>(this.path+url, {
                params: customParams,
                observe: 'response'
              });
     }
  
     getTourById(id:string):Observable<any>
     {
      const url= `/Tour/GetTour/${id}`;
      return this.http.get<any>(this.path+url)
     }
  
     getCategories():Observable<any>{
      const url= `/touroption/gettourcategories`;
      return this.http.get<any>(this.path+url)
     }
  
     getCategoryByLang(lang:string):Observable<any>{
      const url= `/touroption/GetTourCategoriesByLang/${lang}`;
      return this.http.get<any>(this.path+url)
     }
  
     getCategoryById(id:string):Observable<any>{
      const url= `/touroption/gettourcategory${id}`;
      return this.http.get<any>(this.path+url)
     }
  
     getRegions():Observable<any>{
      const url= `/touroption/gettourRegions`;
      return this.http.get<any>(this.path+url)
     }
  
     getRegionByLang(lang:string):Observable<any>{
      const url= `/touroption/gettourregionbylang/${lang}`;
      return this.http.get<any>(this.path+url)
     }
  
     getRegionById(id:string):Observable<any>{
      const url= `/touroption/gettourRegion/${id}`;
      return this.http.get<any>(this.path+url)
     }
  
     getPeriods():Observable<any>{
      const url= `/touroption/gettourperiods`;
      return this.http.get<any>(this.path+url)
     }
  
     getPeriodByLang(lang:string):Observable<any>{
      const url= `/touroption/gettourperiodbylang/${lang}`;
      return this.http.get<any>(this.path+url)
     }
  
     getPeriodById(id:string):Observable<any>{
      const url= `/touroption/gettourperiod/${id}`;
      return this.http.get<any>(this.path+url)
     }
  
     getExitPointByLang(lang:string):Observable<any>{
      const url= `/touroption/gettourexitpointbylang/${lang}`;
      return this.http.get<any>(this.path+url)
     }
  
     getTransportationByLang(lang:string):Observable<any>{
      const url= `/touroption/gettourtransportationbylang/${lang}`;
      return this.http.get<any>(this.path+url)
     }
  
     addReservation(user: Reservation): Observable<Reservation> {
      const url = `/Reservation/AddTourReservation`;
      return this.http.post<Reservation>(this.path+url, user)
    }
  
    //  addReservation(resource:ReservationResource):Observable<any>{
    //   const url= `tour/SearchTour`;
    //   const customParams = new HttpParams()
    //   .set('ContactFirstName', resource.ContactFirstName)
    //   .set('ContactLastName',resource.ContactLastName)
    //   .set('ContactEmail',resource.ContactEmail)
    //   .set('ContactPhone',resource.ContactPhone)
    //   .set('Note',resource.Note)
    //   .set('ContactAddress',resource.ContactAddress)
    //   .set('ContactCity',resource.ContactCity)
    //   .set('ContactState',resource.ContactState)
    //   .set('ContactCountry',resource.ContactCountry)
    //   .set('InvoiceTitle',resource.InvoiceTitle)
    //   .set('TaxNumber',resource.TaxNumber)
    //   .set('TaxAdministration',resource.TaxAdministration)
    //   .set('InvoiceAddress',resource.InvoiceAddress)
    //   .set('InvoiceCity',resource.InvoiceCity)
    //   .set('InvoiceState',resource.InvoiceState)
    //   .set('InvoiceCountry',resource.InvoiceCountry)
    //           return this.http.get<any[]>(this.path+url, {
    //             params: customParams,
    //             observe: 'response'
    //           });
    //  }
  
  
  
  
  
  
  
  
  
  
     getMenus(lang:string ="tr"):Observable<any[]>
     {
      const url= `/Menu/GetMenuRecordsByLanguage/${lang}`;
      return this.http.get<any[]>(this.path+url)
     }
  
     getMenuPage(id:string):Observable<any>
     {
      const url= `/Menu/GetFrontPage/${id}`;
      return this.http.get<any>(this.path+url)
     }
  
    //  getAllTours():Observable<any[]>
    //  {
    //   const url= `Tour/GetAllTours`;
    //   return this.http.get<any[]>(this.path+url)
    //  }
  
     getTour(id:string):Observable<any>
     {
      const url= `/Tour/GetTour/${id}`;
      return this.http.get<any>(this.path+url)
     }
  
     getAllToursForCountry(countryName:string):Observable<any[]>
     {
      const url= `/Tour/GetAllToursForCountry/${countryName}`;
      return this.http.get<any[]>(this.path+url)
     }
  
     getAllToursByLanguage(languageId:string):Observable<any[]>
     {
      const url= `/Tour/GetAllToursByLanguage/${languageId}`;
      return this.http.get<any[]>(this.path+url)
     }
  
     getAllFaqByLanguage(languageId:string):Observable<any[]>
     {
      const url= `/Tour/GetAllToursByLanguage/${languageId}`;
      return this.http.get<any[]>(this.path+url)
     }
  
     getTourSearch(languageId:string,location:string,start:string,end:string):Observable<any[]>
     {
      const url=`/Tour/GetAllTourRecordByLanguage/${languageId&&location&&start&&end}`;
      return this.http.get<any[]>(this.path+url)
     }
  
  }
  