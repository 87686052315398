<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <!-- <h2>About Us</h2> -->
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title"></span>
                    <h2>{{getaboutModel.name}}</h2>
                    <p style="text-align: justify;">{{getaboutModel.value}}</p>

                   
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/img1.jpg" class="shadow" alt="image">
                </div>
            </div>
        </div>

       
    </div>
</section>



<!-- <app-funfacts></app-funfacts> -->

<!-- <app-partner></app-partner> -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-instagram></app-instagram> -->