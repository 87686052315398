import { Component, OnInit } from '@angular/core';
import { TourService } from '../services/tour.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TourdayService } from '../services/tourday.service';
import { SpecificationService } from '../services/specification.service';
import { TourResource } from '../models/tour-resource';
import { environment } from 'src/environments/environment';
// import { Tourspec } from '../models/listing-fourpec';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactForm } from '../models/contactForm';
import Swal from 'sweetalert2';
import { CommentService } from '../services/comment.service';
import { CommentForm } from '../models/commentForm';
import { TourClick } from '../models/click';

@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.component.html',
  styleUrls: ['./listing-details.component.scss']
})
export class ListingDetailsComponent implements OnInit {

  constructor(private tourService: TourService, private commentService: CommentService, private router: ActivatedRoute, private route: Router,private formBuilder: FormBuilder, ) { 
    this.tourId = router.snapshot.params.id;

  }
  guestCount: any;
  adultCount: any = 2;
  adultPrice: any = 0;
  public childCount: any = 0;
  childPrices: any = 0;
  showChildPrice: boolean = false;
  gettourModel!: any;
  getdayModel!: any;
  getspecModel!: any;
  // getfilterModel!: Tourspec;
  ChildPrice: any;
  tourDepartureId:any;
  tourPriceId:any;

  child1: number = 0;
  child2: number = 0;
  child3: number = 0;

  tourDetail: any;
  tourSpecificationInPrice: any;
  tourSpecificationOutPrice: any;

  path = environment.serverUrl
  tourResource: TourResource;
  periods: any[] = []
  selectedPeriod: any = "";
  regions: any[] = []
  selectedRegion: any = "";
  keyword = "regionName";
  tourPrice: any;
  countedPrice: number = 0;
  price: any;
  onePersonPrice: any;
  personCount = 2;
  form!: FormGroup;
  getcommentlistModel!:any;
  tourId: any;

  
  tourClickModel : TourClick


  datePickerConfig = {
    format: 'YYYY-MM-DD'
  };

  ngOnInit(): void {
    this.router.params.subscribe(x => {
      this.gettourlist(x['id']);
      this.getcommentList(x['id']);
      this.commentForm();
          })
  }
  guestChange() {
    setTimeout(() => { this.guestCount = (<HTMLInputElement>document.getElementById("guests")).value, 1 })
  }
  gettourlist(id: any) {
    this.tourService.getid(id).subscribe((data: any) => {
      this.gettourModel = data;
      console.log("Tur detay bilgii",this.gettourModel)
      this.onePersonPrice = this.gettourModel.tourPrices[0].extraPrice
      this.tourPrice = this.gettourModel.tourPrices[0].price;
      this.countedPrice = this.gettourModel.tourPrices[0].price * this.adultCount;
      this.tourDepartureId = this.gettourModel.tourDepartures.find(x=> x.isMain==true).id
    })
     this.tourService.getIPAddress().subscribe((clickdata: any) => {
       console.log("Click deneme", clickdata.ip)
    this.tourClickModel = new TourClick
    this.tourClickModel.activeTourId = this.gettourModel.id
    this.tourClickModel.ipAddress = clickdata.ip

    this.tourService.postclick(this.tourClickModel).subscribe((dataclick : any) => {
      console.log("Click deneme 2", dataclick)
    })
     })
     
  }



  setReservation() {
    const reservationData = {
      personCount: this.personCount,
      childCount: this.childCount,
      countedPrice: this.countedPrice,
      isChild: this.showChildPrice,
      ChildPrice: this.tourPrice,
      childPrices: this.childPrices,
      tourDepartureId: this.tourDepartureId,
      tourPriceId: 7
    }
    console.log("Kişi bilgileri", reservationData)
    localStorage.setItem("reservationData", JSON.stringify(reservationData))
    this.route.navigate(['/checkout', this.gettourModel.id], { skipLocationChange: false })

  }

  calculateTotalPrice(e: number) {
    this.adultCount = e
    this.personCount = this.adultCount;

    if (this.adultCount <= 1) {
      this.adultCount = 1
      this.adultPrice = this.tourPrice * this.adultCount + this.onePersonPrice;
      this.countedPrice = this.adultPrice + this.childPrices
    }
    else {

      this.adultPrice = this.tourPrice * this.adultCount
      this.countedPrice = this.adultPrice + this.childPrices

    }

  }
  calculateChildPrice(e: number) {
    if (e != 0) {
      this.showChildPrice = true;
      this.childCount = e;
    }
    else {
      this.showChildPrice = false;
      this.childCount = 0;
      this.child1 = null;
      this.child2 = null;
      this.child3 = null;
      this.childPrices = 0;

    }
    this.setChild(e)
  }

  setChild(e) {

    this.calculateTotalPrice(this.adultCount);


    let additionalPrice = 0;
    if (this.childCount == 1) {
      additionalPrice = 0
      additionalPrice = Number(this.child1)
    }
    else if (this.childCount == 2) {
      additionalPrice = 0
      additionalPrice = Number(this.child1) + Number(this.child2)
    }
    else if (this.childCount == 3) {
      additionalPrice = 0
      additionalPrice = Number(this.child1) + Number(this.child2) + Number(this.child3);
    }

    this.childPrices = additionalPrice;
    this.countedPrice = this.adultPrice + this.childPrices

    this.personCount = this.adultCount


  }
    getcommentList(id: any) {
      this.commentService.getid(id).subscribe((data: any) => {
        this.getcommentlistModel = data.filter(x => x.isActive);
        console.log("İlk yorummm",this.getcommentlistModel)
      })
    }
  
   commentForm() {
    this.form = this.formBuilder.group({
      activeTourId: this.tourId,
      fullName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      comment: ['', Validators.required],
      point:['',]
    })
  }

  submitForm() {
    console.log(this.form)
    if (this.form.valid) {
      const commentForm: ContactForm = Object.assign(this.form.value);
      Swal.fire({
        title: 'Talebiniz gönderilsin mi?',
        showDenyButton: true,
        confirmButtonText: 'Evet',confirmButtonColor:"#238dc1",
        denyButtonText: `Hayır`, denyButtonColor:"#b47f00",
      }).then((response) => {
        if (response.isConfirmed) {
          this.commentService.contactSend(commentForm).subscribe(data => {
            if (data) {
              Swal.fire({
                title: 'Başarılı',
                text: 'Yorumunuz gönderilmiştir...',
                icon: 'success',iconColor:"#89dc65",
                confirmButtonText: 'Tamam',  confirmButtonColor:"#89dc65" ,
               }  ).then(function (result) {
                if (result.value) {
                  window.location.href = '/listing-details/'+this.activeTourId;
                }
               })
            }
  
          }); 
        } 
      } );
    } else {
      Swal.fire({
        title: 'İletişim bilgilerini doldurun',
        icon: 'warning',iconColor:"#d4c201",
        confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
       }  )
    }
  }


  
}
