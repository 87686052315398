import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SurveryService } from '../services/survery.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blog-one',
  templateUrl: './blog-one.component.html',
  styleUrls: ['./blog-one.component.scss']
})
export class BlogOneComponent implements OnInit {
  form!: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private surveryService:SurveryService
  ) { }

  ngOnInit(): void {
    this.surveryForm();
  }


  surveryForm() {
    this.form = this.formBuilder.group({
      whichTrip: [''],
      generalContactEvaluation: [''],
      informationEvaluation: [''],
      tourTripGeneralEvaluation: [''],
      aeroplanePoint: [''],
      hotelPoint: [''],
      busTransferPoint: [''],
      foodPoint: [''],
      guideLocalPoint: [''],
      guidFromTurkeyPoint: [''],
      officersPoint: [''],
      organizationPoint: [''],
      servicesOfferedGeneralEvaluation: [''],
      travelAnnouncement: [''],
      groupTurProgram: [''],
      suggestionComplaint: [''],
    })
  }

  
  submitForm() {
    // console.log(this.form)
    if (this.form.valid) {
      
      const surveryForm: any = Object.assign(this.form.value);
      Swal.fire({
        title: 'Anketiniz gönderilsin mi?',
        showDenyButton: true,
        confirmButtonText: 'Evet',confirmButtonColor:"#238dc1",
        denyButtonText: `Hayır`, denyButtonColor:"#b47f00",
      }).then((response) => {
        if (response.isConfirmed) {
          this.surveryService.surverySend(surveryForm).subscribe(data => {
            if (data) {
              Swal.fire({
                title: 'Başarılı',
                text: 'Anket formunuz alınmıştır...',
                icon: 'success',iconColor:"#89dc65",
                confirmButtonText: 'Tamam',  confirmButtonColor:"#89dc65" ,
               }  ).then(function (result) {
                if (result.value) {
                  window.location.href = '/';
                }
               })
            }
            
          }); 
        } 
      } );
    } else {
      Swal.fire({
        title: 'Anket bilgilerini doldurun',
        icon: 'warning',iconColor:"#d4c201",
        confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
       }  )
    }
  }



}
