import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'featuredFilter',
   pure: true
})
export class FeaturedFilterPipe implements PipeTransform {
   transform(tourModel: any[], filter: boolean): any[] {
      if (!tourModel) {
        return [];
      }
        return tourModel.filter(tourModel => tourModel.isChildPrice === filter);
    }
}