<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>SIKÇA SORULAN SORULAR</h2>
        </div>
    </div>
</div>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <!-- <li><a href="#"><i class='bx bx-flag'></i> <span>Getting Started</span></a></li> -->
                <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span>SIKÇA SORULAN SORULAR</span></a></li>
                <!-- <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Sales Question</span></a></li> -->
            </ul>
            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Uçuştan ne kadar zaman önce havalimanında olmam gerekiyor ? <i class='bx bx-chevron-down'></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Dış hat uçuşlar için tarifeli uçuşunuzdan 2 saat önce, iç hat seferlerinde ise 1 saat önce havalimanında olmanız gerekmektedir.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    İç hat uçuşlarda kimlik kontrolü yapılıyor mu ? <i class='bx bx-chevron-down'></i>
                                </button>
                            </div>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Evet yapılıyor, yolcularımız yanlarında nüfus cüzdanı,ehliyet veya pasaport bulundurmak zorundadır.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Havalimanı vergisi nedir ? <i class='bx bx-chevron-down'></i>
                                </button>
                            </div>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Devlet tarafından havayollarından alınan vergidir. Uçak bileti ücretinin içine dahil edilmektedir.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Bilet düzenlendikten sonra başka kişi benim yerime uçabilir mi ? <i class='bx bx-chevron-down'></i>
                                </button>
                            </div>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Hayır. Uçak biletlerinde isim ve soyadı değişikliği havayolu kuralları gereği yapılamamaktadır.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Rezerve edilmiş bir bileti en son hangi tarihe kadar satın almak gerekmektedir ?<i class='bx bx-chevron-down'></i>
                                </button>
                            </div>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>Rezerve edilmiş uçuşu satın alabileceğiniz en son tarih, hava yolu firmasına göre değişiklik göstermektedir. Biletinizin rezervasyon süresinin bitiş tarihini 0312 431 6895 nolu telefondan bizi arayarak öğrenebilirsiniz.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</section>

<app-partner></app-partner>