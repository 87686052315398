import { Component, OnInit } from '@angular/core';
import { AboutService } from '../../pages/services/about.service';
import { Setting } from '../../pages/models/about';
import Swal from 'sweetalert2';
import { FormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from '../../pages/services/subscription.service';
import { SubmitForm } from '../../pages/models/submit';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  formBuilder: any;

  form!: FormGroup;

  constructor(private aboutService: AboutService, private subscriptionService: SubscriptionService) { }
  getsettingModel!:Setting;
  getsettinggModel!:Setting;
  getadressModel!:Setting;
  getmailModel!:Setting;



  ngOnInit(): void {
    this.getsettingList();
    this.getsettinggList();
    this.getadressList();
    this.getmailList();
    this.createForm();
  }
  getsettingList(){
    this.aboutService.get("site_adi").subscribe(data=> {
      this.getsettingModel = data;
      // console.log("Ad",this.getsettingModel)
    })
  }
  getsettinggList(){
    this.aboutService.get("site_telefon").subscribe(data=> {
      this.getsettinggModel = data;
      // console.log("Ad",this.getsettinggModel)
    })
  }
  getadressList(){
    this.aboutService.get("site_adres	").subscribe(data=> {
      this.getadressModel = data;
      // console.log("Ad",this.getadressModel)
    })
  }
  getmailList(){
    this.aboutService.get("eposta").subscribe(data=> {
      this.getmailModel = data;
      // console.log("Ad",this.getmailModel)
    })
  }

  createForm() {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    })
  }
  subscriptionForm() {
    console.log(this.form)
    if (this.form.valid) {
      
      Swal.fire({
        title: 'Talebiniz alınmıştır',
        text: 'E-posta adresinizi kontrol ediniz...',
        icon: 'warning',iconColor:"#d4c201",
        confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
     
   
   
       }  )
      ;
    } else {
      Swal.fire({
        title: 'İletişim bilgilerini doldurun',
        icon: 'warning',iconColor:"#d4c201",
        confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
     
   
   
       }  )
    }
  }
}
