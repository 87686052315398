import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../services/category.service';
import { PeriodService } from '../services/period.service';
import { RegionService } from '../services/region.service';
import { TourService } from '../services/tour.service';
import { Router } from '@angular/router';
import { StartpointService } from '../services/startpoint.service';
import { FlightService } from '../services/flight.service';
import { ResponseHeader } from '../models/response-header';
import { BehaviorSubject, of } from 'rxjs';
import { TourResource } from '../models/tour-resource';
import { Category } from '../models/category';
import { Region } from '../models/region';
import { Period } from '../models/period';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-listing-four',
  templateUrl: './listing-four.component.html',
  styleUrls: ['./listing-four.component.scss']
})

  export class ListingFourComponent implements OnInit {

    private tourSubject = new BehaviorSubject<any[]>([]);
    private responseHeaderSubject = new BehaviorSubject<ResponseHeader>(null);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public _count: number = 0;
    constructor(private categoryService: CategoryService, private periodService: PeriodService, private regionService: RegionService, private flightService: FlightService, private startpointService: StartpointService, private tourService: TourService, private route: Router
    ) {
      this.tourResource = new TourResource();
      this.tourResource.pageSize = 20;
      this.tourResource.skip = 0;
      this.tourResource.orderBy = 'createdDate desc'
     }
  
    gettourModel!:any;
    getcategoryModel: Category[];
    getlistModel!: Category[];
    getsearchtour:any []=[];


    getregionModel!: Region[];
    getperiodModel!: Period[];
    getflightModel!: Period[];
    categoryList: any[];
    tourResource: TourResource;
  
  
    tours: any[] = [];
    searchKey = new FormControl();
    localtour: TourResource[] = [];
  
  
  
    path = environment.serverUrl
  
  
  
    ngOnInit(): void {
      this.gettourList();
      this.getcategorylist();
      this.getperiodlist();
      this.getregionlist();
      // console.log("Gelen turlar liste", this.localtour)
    }
    gettourList() {
      this.tourService.getList().subscribe(data => {
        this.gettourModel = data.filter(x =>x.isActive == true);
  // console.log("Turlar",this.gettourModel)
        this.localtour = (JSON.parse(localStorage.getItem("TourSearch")));
        if(this.localtour.length>0){
          this.gettourModel=this.localtour
        }else if(this.localtour.length=0){
          this.gettourModel=data
        }
        this.gettourModel;
      })
    }
    
  searchTour(){
    // console.log("Giden veriler",this.tourResource)
    this.tourService.GetsearchTour(this.tourResource.TourName,this.tourResource.PeriodName, this.tourResource.CategoryName,this.tourResource.SearchTerm, this.tourResource.RegionName).subscribe((resp: any) =>  {
      this.getsearchtour = resp;
      // console.log("A",this.getsearchtour)
      localStorage.setItem('TourSearch', JSON.stringify(this.getsearchtour));
      window.location.href = '/listing-four';
    })
    // this.route.navigateByUrl("/listing-four")
  }
  

 

  getcategorylist() {
    this.categoryService.getList().subscribe(data=> {
      this.getlistModel = data;
      // console.log("Kategori",this.getlistModel)
    })
  }

  getperiodlist(){
    this.periodService.getList().subscribe(data=> {
      this.getperiodModel = data;
      // console.log("aa",this.getperiodModel)

    })
  }
   getregionlist() {
      this.regionService.getList().subscribe(data => {
        this.getregionModel = data;
        // console.log("Bölgeler",this.getregionModel)
      })
    }
    setTours(tour) {
      this.tours.push(tour);
    }
    clearLocalStorage() {
      localStorage.removeItem("regionPeriod")
      localStorage.removeItem("searchKey")
      localStorage.removeItem("category")
      localStorage.removeItem("region")
      localStorage.removeItem("period")
      localStorage.removeItem("transportation")
      localStorage.removeItem("exitpoint")
    }
  
    ngOnDestroy(): void {
      localStorage.removeItem("TourSearch");
    }
  
  
   
    
  
  }

