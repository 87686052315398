<!-- <app-header-one></app-header-one> -->
<style>
    /* Temel stil */
    body {
        font-family: Arial, sans-serif;
    }

    .question-matrix {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    .question-matrix th,
    .question-matrix td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: center;
        position: relative;
    }

    .question-matrix th {
        background-color: #f0f0f0;
        font-weight: bold;
    }

    .question-matrix tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    /* Radio düğmeleri özelleştirmesi */
    .question-matrix input[type="radio"] {
        display: none;
    }

    .question-matrix input[type="radio"]+label {
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        /* background-color: #007bff;  */
        border-radius: 50%;
        /* Yuvarlak kenarlık */
        border: 2px solid transparent;
        /* Şeffaf bir kenarlık */
        color: #fff;
        /* Değişiklik: Beyaz metin */
        position: relative;
        border-color: #000;
    }

    /* Seçim işareti için stil */
    .question-matrix input[type="radio"]:checked+label::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: #000;
        border-radius: 50%;
        /* Yuvarlak kenarlık */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .star-rating {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        background-color: #f0f0f0;
        /* Arka plan rengi */
        padding: 0px;
        /* Aralara boşluk ekleme */
    }

    .star-rating input[type="radio"] {
        display: none;
    }

    .star-rating label {
        font-size: 2.5em;
        /* Yıldız boyutunu artır */
        color: #ccc;
        cursor: pointer;
    }

    .star-rating input[type="radio"]:checked+label {
        color: #ffc107;
        /* Seçilen yıldız rengi */
    }

    .star-rating label:not(:last-child) {
        margin-right: 50px;
        /* Yıldızlar arasına boşluk ekle */
    }


    .checkbox-container {
        display: flex;
        flex-direction: column;
    }

    .checkbox-group {
        display: flex;
        flex-wrap: wrap;
    }

    .checkbox-label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
        font-size: 16px;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .checkbox-label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 18px;
        height: 18px;
        border: 2px solid #ccc;
        border-radius: 50%;
    }

    input[type="checkbox"] {
        opacity: 0;
    }

    input[type="checkbox"]:checked+.checkbox-label:before {
        background-color: #007bff;
        border-color: #007bff;
    }

    input[type="checkbox"]:hover+.checkbox-label:before {
        border: 2px solid #007bff;
    }

    .radio-container {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        margin-right: 15px;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
    }

    .radio-container input[type="radio"] {
        display: none;
    }

    .radio-container .radio-custom {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 2px solid #000;
        border-radius: 0;
    }

    .radio-container .radio-custom::after {
        content: "";
        position: absolute;
        display: none;
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 0;
        background: #000;
    }

    .radio-container input[type="radio"]:checked+.radio-custom::after {
        display: block;
    }
</style>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Bosna Gezisi</h2>
        </div>
    </div>
</div>
<br>

<section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-calendar'></i>
            <span>18 Mayıs 2024 - 21 Mayıs 2024 <a routerLink="/login"></a></span>
        </div>
        <div class="col-lg-12" style="margin: auto;">
            <div class="single-listing-box">
                <a class="listing-image">
                    <img src="assets/img/listing/img2.jpg" alt="image">
                </a>
            </div>
        </div>

        <div class="order-details">
            <h3 class="title">Bosna Gezisi Değerlendirme Anketi</h3>

            <div class="payment-box">
                <div class="payment-method">
                    <p>
                        Sizlere daha iyi hizmet verebilmek için geri bildirim anketini sizinle paylaşıyoruz. Geri
                        Bildirim isteğine yanıt vermek gönüllülüğe dayalıdır.

                    </p>
                    <p>
                        Bir başka Ivati Reisen gezisinde sizleri misafir etmekten mutluluk duyacağız.

                    </p>
                    <!-- <p>
                        <input type="radio" id="cash-on-delivery" name="radio-group">
                        <label for="cash-on-delivery">Cash on Delivery</label>
                    </p> -->
                </div>
            </div>
        </div>
        <br>

        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Anket Formu</h3>

                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>1. Ivati Reisen ile bu kaçıncı seyahatiniz?

                                        <span class="required">*</span></label>
                                    <mat-radio-group aria-label="Select an option" formControlName="whichTrip"
                                        id="whichTrip" name="whichTrip" required>
                                        <mat-radio-button value="1">Birinci</mat-radio-button>
                                        <mat-radio-button value="2">Birden Fazla</mat-radio-button>
                                        <div>
                                        </div>
                                    </mat-radio-group>


                                </div>
                            </div>


                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label>2. Ivati Reisen'in ulaşılabilirlik, servis hizmeti ve genel iletişim hakkında
                                        değerlendirmeniz nedir?<span class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="generalContactEvaluation" id="generalContactEvaluation"
                                                name="generalContactEvaluation" required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div>
                                                </div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>3. Gezi ile ilgili katılımcı olarak sizin bilgilendirilme süreciniz hakkında
                                        değerlendirmeniz nedir?
                                        <span class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="informationEvaluation" id="informationEvaluation"
                                                name="informationEvaluation" required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div>
                                                </div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12"> <!-- Mobil cihazlar için 12 kolon genişlik -->
                                <div class="form-group">
                                    <label>4. Bosna gezisi ile ilgili genel değerlendirmeniz nedir?<span
                                            class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="tourTripGeneralEvaluation"
                                                id="tourTripGeneralEvaluation" name="tourTripGeneralEvaluation"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12"> <!-- Mobil cihazlar için 12 kolon genişlik -->
                                <div class="form-group">
                                    <label>5. Aşağıda sunulan hizmetler hakkında genel değerlendirmeniz nedir? (1 en
                                        düşük ve 6 en iyi değerlendirme seçeneğidir.) *<span
                                            class="required">*</span></label>
                                    <!-- <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="tourTripGeneralEvaluation"
                                                id="tourTripGeneralEvaluation" name="tourTripGeneralEvaluation"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div> -->
                                    <br>
                                    <label>Uçak<span
                                            class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="aeroplanePoint"
                                                id="aeroplanePoint" name="aeroplanePoint"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <br>
                                    <label>Otel<span
                                            class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="hotelPoint"
                                                id="hotelPoint" name="hotelPoint"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <br>
                                    <label>Otobüs/Transfer	<span
                                            class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="busTransferPoint"
                                                id="busTransferPoint" name="busTransferPoint"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <br>
                                    <label>Yemek<span
                                            class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="foodPoint"
                                                id="foodPoint" name="foodPoint"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <br>
                                    <label>Rehber(Yerel)	<span
                                            class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="guideLocalPoint"
                                                id="guideLocalPoint" name="guideLocalPoint"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <br>
                                    <label>Ivati Görevlisi	<span
                                            class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="officersPoint"
                                                id="officersPoint" name="officersPoint"
                                                required>
                                                <mat-radio-button value="1">Çok Kötü</mat-radio-button>
                                                <mat-radio-button value="2">Kötü</mat-radio-button>
                                                <mat-radio-button value="3">Orta</mat-radio-button>
                                                <mat-radio-button value="4">İyi</mat-radio-button>
                                                <mat-radio-button value="5">Çok İyi</mat-radio-button>
                                                <div></div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>



                         



                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>6. Bu geziye tekrar katılma şansınız olsaydı gezi programından çıkartmak
                                        istediğiniz bir bölüm olurmuydu? (Cevabınız Evet ise aşağıda yer alan kutucuk
                                        içerisinde detaylı bilgi verebilirsiniz) <span class="required">*</span></label>
                                    <div class="form-group">
                                        <textarea name="notes" id="notes" cols="30" rows="5" placeholder="...."
                                            class="form-control"></textarea>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>6. Bu geziye tekrar katılma şansınız olsaydı gezi programına eklemek ve
                                        değiştirmek istediğiniz birşey olurmuydu? (Cevabınız Evet ise aşağıda yer alan
                                        kutucuk içerisinde detaylı bilgi verebilirsiniz) <span
                                            class="required">*</span></label>
                                    <div class="form-group">
                                        <textarea name="servicesOfferedGeneralEvaluation"
                                            formControlName="servicesOfferedGeneralEvaluation"
                                            id="servicesOfferedGeneralEvaluation" cols="30" rows="5" placeholder="...."
                                            class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">

                                    <label>7. Bosna Gezisi ile ilgili duyuruyu nerede gördünüz?
                                        <span class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <!-- <input type="checkbox" id="select-all">
                                        <label class="checkbox-label" for="select-all">Tümünü Seç</label> -->
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="travelAnnouncement" id="travelAnnouncement"
                                                name="travelAnnouncement" required>
                                                <mat-radio-button value="1">Dernek Yetkilileri</mat-radio-button>
                                                <mat-radio-button value="2">Sosyal Medya</mat-radio-button>
                                                <mat-radio-button value="3">Din Görevlisi</mat-radio-button>
                                                <mat-radio-button value="4">Tavsiye</mat-radio-button>
                                                <mat-radio-button value="5">İnternet</mat-radio-button>
                                                <div>
                                                </div>
                                            </mat-radio-group>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>8. Ivati Reisen ile bir seyahat programını bir başkasına önerirmisiniz?
                                        <span class="required">*</span></label>
                                    <mat-radio-group aria-label="Select an option" formControlName="isShared"
                                        id="isShared" name="isShared" required>
                                        <mat-radio-button value="1">Evet</mat-radio-button>
                                        <mat-radio-button value="0">Hayır</mat-radio-button>
                                        <div>
                                        </div>
                                    </mat-radio-group>


                                </div>
                            </div>

                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>9. Bir sonraki grup tur programlarından hangisine katılmayı tercih
                                        edersiniz?
                                        <span class="required">*</span></label>

                                    <div class="select-box">
                                        <select class="form-control" formControlName="groupTurProgram" name="groupTurProgram" id="groupTurProgram">
                                            <option selected disabled value="">Lütfen Seçiniz</option>
                                            <option value="1">Bosna</option>
                                            <option value="2">Özbekistan</option>
                                            <option value="3">Endülüs</option>
                                            <option value="4">Hac/Umre</option>
                                            <option value="5">Ürdün</option>
                                            <option value="6">Türkiye(Güney Doğu)</option>
                                            <option value="7">
                                                Büyük Balkan Turu (Bosna, Kosova, Kuzey Makedonya ve Karadağ)

                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">

                                    <label>9. Bir sonraki grup tur programlarından hangisine katılmayı tercih
                                        edersiniz?
                                        <span class="required">*</span></label>
                                    <div class="checkbox-container">
                                        <!-- <input type="checkbox" id="select-all">
                                        <label class="checkbox-label" for="select-all">Tümünü Seç</label> -->
                                        <div class="checkbox-group">
                                            <mat-radio-group aria-label="Select an option"
                                                formControlName="groupTurProgram" id="groupTurProgram"
                                                name="groupTurProgram" required>
                                                <mat-radio-button value="1">Bosna</mat-radio-button>
                                                <mat-radio-button value="2">Özbekistan</mat-radio-button>
                                                <mat-radio-button value="3">Endülüs</mat-radio-button>
                                                <mat-radio-button value="4">Hac/Umre</mat-radio-button>
                                                <mat-radio-button value="5">Ürdün</mat-radio-button>
                                                <mat-radio-button value="5">Türkiye(Güney Doğu)</mat-radio-button>

                                                <div>
                                                </div>
                                            </mat-radio-group>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>10. Önerileriniz veya şikayetleriniz varsa lütfen bizimle paylaşınız.
                                        <span class="required">*</span></label>
                                    <div class="form-group">
                                        <textarea name="suggestionComplaint" id="suggestionComplaint"
                                            formControlName="suggestionComplaint" cols="30" rows="5" placeholder="...."
                                            class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <!-- <a class="default-btn" type="submit">Anketini Gönder </a> -->
                                <button class="btn contact-btn" type="submit" style="    display: inline-block;
                                background-color: #ee8925;
                                color: #fff;
                                transition: 0.5s;
                                border: none;
                                padding: 11.5px 30px 12px;
                                border-radius: 30px;
                                font-size: 15px;
                                font-weight: 600;">Anketi Gönder</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>



<style>
    body {
        font-family: Arial, sans-serif;
        background-color: #f0f0f0;
        /* Genel arka plan rengi */
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .checkbox-container {
        background-color: #fff;
        /* Beyaz arka plan rengi */
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px;
        max-width: 100%;
        /* Öğenin maksimum genişliği */
        width: 100%;
        /* Öğenin tam genişlikte olması */
    }

    .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        /* Öğelerin alt alta geçişi sağlar */
        justify-content: space-between;
    }

    mat-radio-group {
        display: flex;
        flex-wrap: wrap;
        /* Öğelerin alt alta geçişi sağlar */
        justify-content: space-between;
        width: 100%;
    }

    mat-radio-button {
        margin: 0 10px;
        flex: 1;
        /* Öğelerin esnek genişliği ayarlanır */
        max-width: 100%;
        /* Öğelerin tam genişlikte olması sağlanır */
    }
</style>