<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/mobile.jpg" alt="image">
                </div>
            </div>

            

            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <!-- <span class="sub-title">IVATI REISEN</span> -->
                    <h2>{{getcommunicationModel?.name}}</h2>
                    <p >{{getcommunicationModel?.value}}</p> 

                    <div class="btn-box">
                        <!-- <a routerLink="" class="apple-store-btn"> -->
                            <a href="tel:+41768035434" class="apple-store-btn">

                            <img src="assets/img/applestore.png" alt="image">

                            <span>+41 76 803 54 34</span>
                        </a>
                        
                        <!-- <a routerLink="/" class="play-store-btn">
                            <img src="assets/img/playstore.png" alt="image">
                            Get it on
                            <span>Google Play</span>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
