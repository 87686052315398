<app-header-one></app-header-one>

<div class="page-title-area page-title-bg4">
    <div class="container">
        <div class="page-title-content">
            <h2>Categories</h2>
        </div>
    </div>
</div>

<section class="listing-categories-area bg-F7F9FB pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-shopping-bag'></i>
                        Shops
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-bed'></i>
                        Hotels
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-drink'></i>
                        Restaurants
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-dumbbell'></i>
                        Fitness
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-calendar-star'></i>
                        Events
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-walk'></i>
                        Services
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-building-house'></i>
                        Hospital
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4">
                <div class="single-listing-category">
                    <a routerLink="/listing-two">
                        <i class='bx bx-buildings'></i>
                        Market
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-partner></app-partner>