import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { ResponseHeader } from '../models/response-header';
import { CategoryService } from '../services/category.service';
import { PeriodService } from '../services/period.service';
import { RegionService } from '../services/region.service';
import { FlightService } from '../services/flight.service';
import { StartpointService } from '../services/startpoint.service';
import { TourService } from '../services/tour.service';
import { Router } from '@angular/router';
import { TourResource } from '../models/tour-resource';
import { Region } from '../models/region';
import { Period } from '../models/period';
import { Category } from '../models/category';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { catchError, finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-listing-five',
  templateUrl: './listing-five.component.html',
  styleUrls: ['./listing-five.component.scss']
})
export class ListingFiveComponent implements OnInit {

  private tourSubject = new BehaviorSubject<any[]>([]);
  private responseHeaderSubject = new BehaviorSubject<ResponseHeader>(null);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public _count: number = 0;
  constructor(private categoryService: CategoryService, private periodService: PeriodService, private regionService: RegionService, private flightService: FlightService, private startpointService: StartpointService, private tourService: TourService, private route: Router
  ) { }

  gettourModel!: TourResource[];
  getcategoryModel: Category[];
  getregionModel!: Region[];
  getperiodModel!: Period[];
  getflightModel!: Period[];
  categoryList: any[];
  tourResource: TourResource;


  tours: any[] = [];
  searchKey = new FormControl();
  localtour: TourResource[] = [];



  path = environment.serverUrl



  ngOnInit(): void {
    this.gettourList();
    this.getcategoryList();
    this.getregionlist();
    this.getperiodlist();
    this.getflightlist();
    console.log("Gelen turlar liste", this.localtour)
    

  }


  gettourList() {
    this.tourService.getList().subscribe(data => {
      this.gettourModel = data;
console.log("Turlar",this.gettourModel)
      this.localtour = (JSON.parse(localStorage.getItem("TourSearch")));
      if(this.localtour.length>0){
        this.gettourModel=this.localtour
      }else if(this.localtour.length=0){
        this.gettourModel=data
      }
      this.gettourModel;
    })
  }
  getcategoryList() {
    this.categoryService.getList().subscribe(data => {
      this.getcategoryModel = data;
      // console.log("Kategorilerr",this.getcategoryModel)
    })
  }
  getregionlist() {
    this.regionService.getList().subscribe(data => {
      this.getregionModel = data;
      // console.log("Bölgeler",this.getregionModel)
    })
  }
  getperiodlist() {
    this.periodService.getList().subscribe(data => {
      this.getperiodModel = data;
      // console.log("Dönemler",this.getperiodModel)
    })
  }
  getflightlist() {
    this.flightService.getList().subscribe(data => {
      this.getflightModel = data;
      // console.log("Ulaşım",this.getflightModel)
    })
  }

  // search(){
  //   console.log("Okey",this.tourResource)
  //   localStorage.setItem("TourSearch",JSON.stringify(this.tourResource));
  //   this.route.navigateByUrl("/listing-two")
  // }

  loadTours() {
    if (this.tourResource != null) {
      this.tourService.getTourSearch(this.tourResource)
        .pipe(
          catchError(() => of([])),
          finalize(() => { }
          ))
        .subscribe((resp: HttpResponse<any>) => {
          const paginationParam = JSON.parse(resp.headers.get('X_Pagination')) as ResponseHeader
          this.responseHeaderSubject.next(paginationParam);

          // console.log("TUR", resp.body)
          // this.tours = resp.body;
          if (resp.body.length > 0) {

            resp.body.forEach(element => {
              this.tours.push(element);
            })
          }
          const tours = [...resp.body]
          this._count = tours.length
        })
    } else {
      this.tourService.getList().pipe(
        catchError(() => of([])),
        finalize(() => { }
        )).subscribe((resp: HttpResponse<any>) => {
          const paginationParam = JSON.parse(resp.headers.get('X_Pagination')) as ResponseHeader
          this.responseHeaderSubject.next(paginationParam);
          if (resp.body.length > 0) {
            resp.body.forEach(element => {
              this.tours.push(element);
            })
          }
          const tours = [...resp.body]
          this._count = tours.length
        })
    }
  }
  setTours(tour) {
    this.tours.push(tour);
  }
  clearLocalStorage() {
    localStorage.removeItem("regionPeriod")
    localStorage.removeItem("searchKey")
    localStorage.removeItem("category")
    localStorage.removeItem("region")
    localStorage.removeItem("period")
    localStorage.removeItem("transportation")
    localStorage.removeItem("exitpoint")
  }

  ngOnDestroy(): void {
    localStorage.removeItem("TourSearch");
  }


 
  

}

