import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';





@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    path: string = environment.apiUrl;


    constructor(
        private httpClient: HttpClient) { }

    add(data: any): any {
        return this.httpClient.post<any>(this.path+"/Contact/AddNewsletterSubscription", data);
    }
}