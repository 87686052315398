<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Team</h2>
        </div>
    </div>
</div>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img1.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>James Anderson</h3>
                        <span>CEO & Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img2.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Sarah Taylor</h3>
                        <span>Co-Founder</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img3.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/img4.jpg" class="shadow" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="member-content">
                        <h3>John Capabel</h3>
                        <span>Programer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-video></app-video>

<app-feedback></app-feedback>