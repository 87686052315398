<section class="funfacts-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-bullseye'></i>
                    <p>Yeni Ziyaretçiler</p>
                    <h3 class="odometer" data-count="5421">1</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-group'></i>
                    <p>Rezervasyon</p>
                    <h3 class="odometer" data-count="4579">00</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-shape-polygon'></i>
                    <p>Mutlu Müşteri</p>
                    <h3 class="odometer" data-count="499">00</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-trophy'></i>
                    <p>İletişim</p>
                    <h3 class="odometer" data-count="52">00</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>