<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Markalarımız</h2>
        </div>
        
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/MUSDAV.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/VAKIFGLOBAL.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/VAKUR.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/VAKIFTUR.png" alt="image"></a>
            </div>

            <!-- <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img5.png" alt="image"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img6.png" alt="image"></a>
            </div> -->
        </div>
    </div>
</div>

