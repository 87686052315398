<section class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/black-logo.png" alt="image"></a>
                            </div>

                            <h3>Welcome Back</h3>
                            <p>New to Louise? <a routerLink="/register">Sign up</a></p>

                            <form>
                                <div class="form-group mb-3">
                                    <input type="email" placeholder="Your Email Address" class="form-control">
                                </div>

                                <div class="form-group mb-3">
                                    <input type="password" placeholder="Your Password" class="form-control">
                                </div>

                                <button type="submit">Login</button>
                                
                                <div class="forgot-password">
                                    <a routerLink="/">Forgot Password?</a>
                                </div>

                                <div class="connect-with-social">
                                    <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Connect with Facebook</button>
                                    <button type="submit" class="twitter"><i class='bx bxl-twitter'></i> Connect with Twitter</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>