<app-header-one></app-header-one>

<div class="page-title-area page-title-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Tur Talebi</h2>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row" style="background: #ffffff;
        border: 2px solid #F4F4F4;
        box-shadow: 0 4px 24px #e1e1e140;
        border-radius: 10px;
        padding: 24px;">
            <div class="col-lg-6 col-md-6 grid-item">
                <div class="single-blog-post">
                    <div class="post-image">
                            <img src="assets/img/talep.png" alt="image" style="border-radius: 20px;">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <h3>Geri Bildirimleriniz Bizim İçin Çok Değerli</h3>
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Adınız<span>*</span></label>
                                    <input type="text"  name="firstName" formControlName="firstName" id="name" class="form-control" placeholder="Ad">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Soyadınız <span>*</span></label>
                                    <input type="text"  name="lastName" formControlName="lastName" id="name" class="form-control" placeholder="Soyad">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Mail adresiniz <span>*</span></label>
                                    <input type="email" id="email" name="email" formControlName="email"  class="form-control" placeholder="E-Posta Adresiniz">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Telefon numaranız<span>*</span></label>
                                    <input type="number" name="phone" id="phone" formControlName="phone" class="form-control" placeholder="Telefon Numaranız">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Talebiniz<span>*</span></label>
                                    <input type="text"  name="subject" formControlName="subject" id="name" class="form-control" placeholder="Mesajınızı yazınız.">
                                </div>
                            </div>
                        
                           
                            <div class="col-lg-12 col-md-12">
                                <button type="submit"  class="default-btn">Talebi Gönder</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>