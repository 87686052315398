import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    path: string = environment.apiUrl;
    lang: string = "tr"

    constructor(
        private httpClient: HttpClient){}

        getList():any {
            return this.httpClient.get<any>(this.path+"/Category/GetAllCategoryByLang/" + this.lang);
        }
}