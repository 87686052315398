import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../services/contact.service';
import { ContactForm } from '../models/contactForm';
import Swal from 'sweetalert2';
import { AboutService } from '../services/about.service';
import { Setting } from '../models/about';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form!: FormGroup;


  constructor(private formBuilder: FormBuilder, private contactService: ContactService,private aboutService: AboutService) { }
  getsettingModel!:Setting;
  getsettinggModel!:Setting;
  getadressModel!:Setting;
  getmailModel!:Setting;
  getworkinghoursModel!:Setting;


  ngOnInit(): void {
    this.createForm();

    this.getsettingList();
    this.getsettinggList();
    this.getadressList();
    this.getmailList();
    this.getworkinghoursList();
  }

  createForm() {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      phone:['', Validators.required]
      // confirmContract: ['', Validators.required]
    })
  }

    submitForm() {
    console.log(this.form)
    if (this.form.valid) {
      
      const contactForm: ContactForm = Object.assign(this.form.value);
      Swal.fire({
        title: 'Talebiniz gönderilsin mi?',
        showDenyButton: true,
        confirmButtonText: 'Evet',confirmButtonColor:"#238dc1",
        denyButtonText: `Hayır`, denyButtonColor:"#b47f00",
      }).then((response) => {
        if (response.isConfirmed) {
          this.contactService.contactSend(contactForm).subscribe(data => {
            if (data) {
              Swal.fire({
                title: 'Başarılı',
                text: 'İletişim talebiniz alınmıştır...',
                icon: 'success',iconColor:"#89dc65",
                confirmButtonText: 'Tamam',  confirmButtonColor:"#89dc65" ,
               }  ).then(function (result) {
                if (result.value) {
                  window.location.href = '/contact';
                }
               })
            }
            
          }); 
        } 
      } );
    } else {
      Swal.fire({
        title: 'İletişim bilgilerini doldurun',
        icon: 'warning',iconColor:"#d4c201",
        confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
       }  )
    }
  }



 
  getsettingList(){
    this.aboutService.get("site_adi").subscribe(data=> {
      this.getsettingModel = data;
      // console.log("Ad",this.getsettingModel)
    })
  }
  getsettinggList(){
    this.aboutService.get("site_telefon").subscribe(data=> {
      this.getsettinggModel = data;
      // console.log("Ad",this.getsettinggModel)
    })
  }
  getadressList(){
    this.aboutService.get("site_adres	").subscribe(data=> {
      this.getadressModel = data;
      // console.log("Ad",this.getadressModel)
    })
  }
  getmailList(){
    this.aboutService.get("eposta").subscribe(data=> {
      this.getmailModel = data;
      // console.log("Ad",this.getmailModel)
    })
  }
  getworkinghoursList(){
    this.aboutService.get("calismasaatleri").subscribe(data=> {
      this.getworkinghoursModel = data;
      // console.log("Ad",this.getworkinghoursModel)
    })
  }

  

}
