import { Component, OnInit } from '@angular/core';
import { TourService } from '../services/tour.service';
import { TourResource } from '../models/tour-resource';
import { environment } from 'src/environments/environment';
import { CategoryService } from '../services/category.service';
import { PeriodService } from '../services/period.service';
import { RegionService } from '../services/region.service';
import { FlightService } from '../services/flight.service';
import { StartpointService } from '../services/startpoint.service';
import { Category } from '../models/category';
import { Region } from '../models/region';
import { Period } from '../models/period';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { ResponseHeader } from '../models/response-header';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-listing-two',
  templateUrl: './listing-two.component.html',
  styleUrls: ['./listing-two.component.scss']
})
export class ListingTwoComponent implements OnInit {

  private tourSubject = new BehaviorSubject<any[]>([]);
  private responseHeaderSubject = new BehaviorSubject<ResponseHeader>(null);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public _count: number = 0;
  constructor(private categoryService: CategoryService, private periodService: PeriodService, private regionService: RegionService, private flightService: FlightService, private startpointService: StartpointService, private tourService: TourService, private route: Router
  ) { }

  gettourModel!: TourResource[];
  getcategoryModel: Category[];
  getregionModel!: Region[];
  getperiodModel!: Period[];
  getflightModel!: Period[];
  getsearchtour:any []=[];
  getlistModel!: Category[];


  categoryList: any[];
  tourResource: TourResource;


  tours: any[] = [];
  searchKey = new FormControl();
  localtour: TourResource[] = [];



  path = environment.serverUrl



  ngOnInit(): void {
    this.gettourList();
    this.getcategoryList();
    this.getregionlist();
    this.getperiodlist();
    this.getflightlist();
    // console.log("Gelen turlar liste", this.localtour)
    

  }


  gettourList() {
    this.tourService.getList().subscribe(data => {
      this.gettourModel = data;
// console.log("Turlar",this.gettourModel)
      this.localtour = (JSON.parse(localStorage.getItem("TourSearch")));
      if(this.localtour.length>0){
        this.gettourModel=this.localtour
      }else if(this.localtour.length=0){
        this.gettourModel=data
      }
      this.gettourModel;
    })
  }
  getcategoryList() {
    this.categoryService.getList().subscribe(data => {
      this.getcategoryModel = data;
      // console.log("Kategorilerr",this.getcategoryModel)
    })
  }
  getregionlist() {
    this.regionService.getList().subscribe(data => {
      this.getregionModel = data;
      // console.log("Bölgeler",this.getregionModel)
    })
  }
  getperiodlist() {
    this.periodService.getList().subscribe(data => {
      this.getperiodModel = data;
      // console.log("Dönemler",this.getperiodModel)
    })
  }
  getflightlist() {
    this.flightService.getList().subscribe(data => {
      this.getflightModel = data;
      // console.log("Ulaşım",this.getflightModel)
    })
  }

  // search(){
  //   console.log("Okey",this.tourResource)
  //   localStorage.setItem("TourSearch",JSON.stringify(this.tourResource));
  //   this.route.navigateByUrl("/listing-two")
  // }

  loadTours() {
    if (this.tourResource != null) {
      this.tourService.getTourSearch(this.tourResource)
        .pipe(
          catchError(() => of([])),
          finalize(() => { }
          ))
        .subscribe((resp: HttpResponse<any>) => {
          const paginationParam = JSON.parse(resp.headers.get('X_Pagination')) as ResponseHeader
          this.responseHeaderSubject.next(paginationParam);

          // console.log("TUR", resp.body)
          // this.tours = resp.body;
          if (resp.body.length > 0) {

            resp.body.forEach(element => {
              this.tours.push(element);
            })
          }
          //  this.tours = resp.body

          // this.setTours(resp.body);

          const tours = [...resp.body]
          this._count = tours.length

          // this.tourSubject.next(tours);
          // console.log("ARAMA SONUÇLARI", this.tours)
        })
    } else {
      this.tourService.getList().pipe(
        catchError(() => of([])),
        finalize(() => { }
          // this.loadingSubject.next(false)
        )).subscribe((resp: HttpResponse<any>) => {
          const paginationParam = JSON.parse(resp.headers.get('X_Pagination')) as ResponseHeader
          this.responseHeaderSubject.next(paginationParam);
          // console.log("TUR", resp.body)
          if (resp.body.length > 0) {
            resp.body.forEach(element => {
              this.tours.push(element);
            })
          }
          const tours = [...resp.body]
          this._count = tours.length
          // console.log("ARAMA SONUÇLARI", this.tours)
        })
    }
  }
  setTours(tour) {
    this.tours.push(tour);
  }
  // searchTour() {
  //   this.tours = new Array();
  //   this.tourResource = new TourResource();
  //   if (this.searchKey.value != null) {
  //     this.tours = new Array()
  //     this.tourResource.TourName = this.searchKey.value;
  //     this.loadTours();
  //   }
  // }
  

  clearLocalStorage() {
    localStorage.removeItem("regionPeriod")
    localStorage.removeItem("searchKey")
    localStorage.removeItem("category")
    localStorage.removeItem("region")
    localStorage.removeItem("period")
    localStorage.removeItem("transportation")
    localStorage.removeItem("exitpoint")
  }

  ngOnDestroy(): void {
    localStorage.removeItem("TourSearch");
  }

  selectedPopularTour(searchTerm:string){
    this.tourResource.RegionName = searchTerm;
    this.searchTour()
  }
  selectedCategoryTour(searchTerm:string){
    this.tourResource.CategoryName = searchTerm;
    this.searchTour()
  }
  searchTour(){
    // console.log("Giden veriler",this.tourResource)
    this.tourService.GetsearchTour(this.tourResource.TourName,this.tourResource.PeriodName, this.tourResource.CategoryName,this.tourResource.SearchTerm, this.tourResource.RegionName).subscribe((resp: any) =>  {
      this.getsearchtour = resp;
      // console.log("A",this.getsearchtour)
      localStorage.setItem('TourSearch', JSON.stringify(this.getsearchtour));
    })
    this.route.navigateByUrl("/listing-four")
  }
}
